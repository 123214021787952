export default {
  scrollToDisplayElement(displayElement, scrollElement = window) {
    const bounds = this.getBoundaries(displayElement, scrollElement);
    this.scrollToBounds(bounds, scrollElement);
  },

  scrollToTopDisplayElement(displayElements, scrollElement = window) {
    const allBounds = displayElements.map(el => this.getBoundaries(el, scrollElement));
    const topBounds = allBounds.sort((a, b) => a.disp.top - b.disp.top)[0];
    this.scrollToBounds(topBounds, scrollElement);
  },

  scrollToBounds(bounds, scrollElement) {
    const scrollAmount = this.amountNeededToScrollToDisplayEntireDisplayElement(bounds.disp, bounds.scr);
    const scrollDestination = this.currentScrollElementPosition(scrollElement) + scrollAmount;
    scrollElement.scroll({
      top: scrollDestination,
      behavior: 'smooth',
    });
  },

  addDelayedPulseToDisplayELement(displayElement) {
    setTimeout(() => {
      displayElement.classList.add('background-pulse');
    }, 500);
  },

  addHighlightToDisplayElement(displayElement, duration) {
    displayElement.classList.add('highlighted');
    if (duration) {
      setTimeout(() => {
        displayElement.classList.remove('highlighted');
      }, duration);
    }
  },

  // ~~~~~~~
  // HELPERS
  // ~~~~~~~

  getBoundaries(displayEl, scrollEl) {
    // "Viewport" refers to everything currently displayed. Top to bottom at your current scroll position.
    // "scrollField" refers to the area of scrollEl that is visible at this scroll position.
    // We want to find the top and bottom of field relative to viewport and the top and bottom of displayEl relative to viewport.
    let scrollFieldRelativeToViewport;
    if (scrollEl === window) {
      // If scrollEl === window, then the visible scrollField is the viewport itself.
      scrollFieldRelativeToViewport = {
        top: 0,
        bottom: window.innerHeight,
      };
    } else {
      // If scrollEl is another div, getBoundingClientRect will give you it's position relative to the viewport.
      scrollFieldRelativeToViewport = {
        top: scrollEl.getBoundingClientRect().top,
        bottom: scrollEl.getBoundingClientRect().bottom,
      };
    }
    const displayBoundsRelativeToViewport = {
      top: displayEl.getBoundingClientRect().top,
      bottom: displayEl.getBoundingClientRect().bottom,
    };
    return {
      disp: displayBoundsRelativeToViewport,
      scr: scrollFieldRelativeToViewport,
    };
  },

  amountNeededToScrollToDisplayEntireDisplayElement(disp, scr) {
    const buffer = 16; // Arbitrary padding for the scroll
    if (disp.top < scr.top) {
      // Completely or partially above scroll bounds, negative scroll to align the tops
      return disp.top - scr.top - buffer;
    } else if (disp.bottom > scr.bottom) {
      // Completely or partially below scroll bounds, positive scroll to align the bottoms
      return disp.bottom - scr.bottom + buffer;
    }
    // Within the bounds, don't scroll
    return 0;
  },

  currentScrollElementPosition(el) {
    // Determine how many pixels the scrollElement is currently scrolled down
    return el === window ? el.scrollY : el.scrollTop;
  },
};
