// A composable for use on pages where we want to warn of unsaved changes before leaving the page (refreshing, going back, etc)
// Pass in a "record" (any data structure) and the composable will track changes to it,
// firing off a warning on page unload if any changes have occurred.
import { computed, onMounted, onUnmounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';

const usePageUnloadWarnings = (record) => {
  const { state, commit } = useStore();

  const unpersistedChanges = computed(() => {
    if (state.formSubmitted) { return false; }
    return !_.isEqual(record, state.persistedRecord);
  });

  const handleWindowUnload = () => {
    if (unpersistedChanges.value) {
      return ''; // Defaults to the regular "Changes made" warning
    }
  };

  // Lifecycle Hooks
  // NOTE: Remember that putting this composable on a component with it's own lifecycle hooks
  // will result in them firing off one after the other (component's first, composable's second)
  onMounted(() => {
    // NOTE: Some form values may be calculated on the front-end after we get the JSON
    // for saved values from the server (like a default `fixed_meeting_time_at` when
    // the feature is not enabled). `$nextTick` is a bit of a hack to leave time for
    // this calculation, but it allows us to avoid adding unnecessary watchers.
    nextTick(() => {
      if (record) commit('update', { key: 'persistedRecord', value: _.cloneDeep(record) });
      window.onbeforeunload = handleWindowUnload;
    });
  });

  onUnmounted(() => {
    window.onbeforeunload = null;
  });

  return { };
};

export default usePageUnloadWarnings;
